import React from 'react';
import { Calendar, MapPin, ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { formatDateDDMM } from '../utils/dateUtils';

const FormIndicator = ({ result }) => {
    const getColor = (result) => {
        switch(result) {
            case 'W': return 'bg-green-500';
            case 'L': return 'bg-red-500';
            case 'D': return 'bg-gray-500';
            default: return 'bg-gray-300';
        }
    };

    return (
        <span className={`inline-block w-5 h-5 ${getColor(result)} text-white text-xs font-bold rounded-full flex items-center justify-center`}>
            {result}
        </span>
    );
};

const TeamStats = ({ position, form }) => (
    <div className="flex items-center gap-0.5 bg-gray-100 rounded-lg p-0.5">
        <div className="bg-gray-200 text-gray-700 px-2 py-0.5 rounded-lg text-sm">
            {position}e
        </div>
        <div className="flex gap-0.5">
            {form.map((result, idx) => (
                <FormIndicator key={idx} result={result} />
            ))}
        </div>
    </div>
);

export const MatchCard = ({ 
    match, 
    standings, 
    results,
    showNavigationArrow = false,
    onClick,
    title = "Match",
    className = "",
    selectedTeam
}) => {
    const { t } = useTranslation();
    if (!match) return null;

    const getTeamStanding = (teamName) => {
        return standings?.find(s => s.team === teamName);
    };

    const getRecentForm = (teamName) => {
        return results
            ?.filter(r => r.team1 === teamName || r.team2 === teamName)
            .slice(0,4)
            .map(result => {
                const [score1, score2] = result.score.split('-').map(Number);
                const isTeam1 = result.team1 === teamName;
                const teamScore = isTeam1 ? score1 : score2;
                const opponentScore = isTeam1 ? score2 : score1;
                
                if (teamScore > opponentScore) return 'W';
                if (teamScore < opponentScore) return 'L';
                return 'D';
            })
            .reverse();
    };

    const getPreviousMatch = () => {
        if (!match || !results?.length) return null;
        
        return results.find(result => 
            (result.team1 === match.team1 && result.team2 === match.team2) ||
            (result.team1 === match.team2 && result.team2 === match.team1)
        );
    };

    const getMatchOutcome = (previousMatch, teamName) => {
        if (!previousMatch) return null;
        const [score1, score2] = previousMatch.score.split('-').map(Number);
        const isTeam1 = previousMatch.team1 === teamName;
        const teamScore = isTeam1 ? score1 : score2;
        const opponentScore = isTeam1 ? score2 : score1;
        
        if (teamScore > opponentScore) return 'W';
        if (teamScore < opponentScore) return 'L';
        return 'D';
    };

    const previousMatch = getPreviousMatch();
    const outcome = getMatchOutcome(previousMatch, selectedTeam);

    const cardClasses = `bg-white rounded-lg shadow-md p-4 mb-4 ${onClick ? 'cursor-pointer' : ''} ${className}`;

    return (
        <div className={cardClasses} onClick={onClick}>
            <div className="flex justify-between items-center mb-1">
                <h2 className="text-sm font-bold uppercase tracking-wide">{title}</h2>
                {showNavigationArrow && (
                    <ChevronRight className="w-4 h-4 text-gray-400" />
                )}
            </div>
            <div className="h-0.5 bg-orange-500 mb-4"></div>
            <div className="space-y-5">
                <div className="flex justify-between items-center text-gray-600">
                    <div className="flex items-center gap-1">
                        <Calendar className="w-4 h-4" />
                        <span>{match.date}</span>
                    </div>
                    <div className="flex items-center gap-1">
                        <MapPin className="w-4 h-4" />
                        <span>{match.location}</span>
                    </div>
                </div>

                <div className="flex flex-col items-center">
                    <div className="flex w-full justify-between items-center gap-2">
                        <div className="flex-1 text-lg font-bold text-left">
                            <span className="break-words">{match.team1}</span>
                        </div>
                        <div className={`text-xl font-semibold text-gray-500 px-2 sm:px-4 whitespace-nowrap`}>
                            {match.time.split('-')[0].trim()}
                        </div>
                        <div className="flex-1 text-lg font-bold text-right">
                            <span className="break-words">{match.team2}</span>
                        </div>
                    </div>
                </div>

                {standings && (
                    <div className="grid grid-cols-[1fr_auto_1fr] gap-4 items-center">
                        <div className="flex justify-start">
                            {getTeamStanding(match.team1) && (
                                <TeamStats 
                                    position={getTeamStanding(match.team1).position}
                                    form={getRecentForm(match.team1)}
                                />
                            )}
                        </div>
                        <div></div>
                        <div className="flex justify-end">
                            {getTeamStanding(match.team2) && (
                                <TeamStats 
                                    position={getTeamStanding(match.team2).position}
                                    form={getRecentForm(match.team2)}
                                />
                            )}
                        </div>
                    </div>
                )}

                {previousMatch && (
                    <div className="p-2 rounded-lg bg-gray-100">
                        <div className="flex justify-between items-center mb-2">
                            <div className="text-xs text-gray-800/75">{t('match.previousMatch')}</div>
                            <div className="text-xs text-gray-800/75">{formatDateDDMM(previousMatch.date)}</div>
                        </div>
                        <div className="flex justify-between items-center gap-2">
                            <span className="text-sm text-gray-900">
                                {outcome === 'W' ? t('match.won') : outcome === 'L' ? t('match.lost') : t('match.draw')}
                            </span>
                            <span className="text-sm font-medium text-gray-900">{previousMatch.score}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}; 