import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCompetitionData } from './hooks/useCompetitionData';
import { LoadingSpinner } from './shared/LoadingSpinner';
import { PreferencesSection } from './competition/PreferencesSection';
import { LastUpdatedInfo } from './competition/LastUpdatedInfo';
import { NextMatch } from './competition/NextMatch';
import { LastResult } from './competition/LastResult';
import { Standings } from './competition/Standings';
import { Program } from './competition/Program';
import { Results } from './competition/Results';
import { MatchDetail } from './competition/MatchDetail';

const MainView = ({ showMatchDetail = false }) => {
    const { t } = useTranslation();
    const {
        selectedSeason,
        setSelectedSeason,
        seasons,
        selectedCompetition,
        setSelectedCompetition,
        selectedTeam,
        setSelectedTeam,
        availableTeams,
        competitions,
        standings,
        results,
        program,
        nextMatch,
        lastResult,
        isLoading,
        error,
        lastUpdated,
        isRefreshing,
        isEditingPreferences,
        handleSavePreferences,
        handleEditPreferences,
        handleRefresh
    } = useCompetitionData();

    if (showMatchDetail) {
        return (
            <MatchDetail
                program={program}
                standings={standings}
                results={results}
                selectedTeam={selectedTeam}
            />
        );
    }

    return (
        <div>
            <div className="container mx-auto px-4 pb-8 pt-4">
                <PreferencesSection 
                    isEditingPreferences={isEditingPreferences}
                    selectedSeason={selectedSeason}
                    setSelectedSeason={setSelectedSeason}
                    seasons={seasons}
                    selectedCompetition={selectedCompetition}
                    setSelectedCompetition={setSelectedCompetition}
                    selectedTeam={selectedTeam}
                    setSelectedTeam={setSelectedTeam}
                    competitions={competitions}
                    availableTeams={availableTeams}
                    handleSavePreferences={handleSavePreferences}
                    handleEditPreferences={handleEditPreferences}
                />
                
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
                        {t('common.error')}
                    </div>
                )}

                {!isEditingPreferences && (
                    <LastUpdatedInfo 
                        lastUpdated={lastUpdated}
                        handleRefresh={handleRefresh}
                        isRefreshing={isRefreshing}
                    />
                )}

                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <NextMatch 
                                match={nextMatch}
                                standings={standings}
                                results={results}
                                program={program}
                                selectedTeam={selectedTeam}
                            />
                            <LastResult result={lastResult} />
                        </div>
                        <Standings 
                            standings={standings}
                            selectedTeam={selectedTeam}
                        />
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <Program 
                                program={program}
                                selectedTeam={selectedTeam}
                                standings={standings}
                                results={results}
                            />
                            <Results 
                                results={results}
                                selectedTeam={selectedTeam}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default MainView;